import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import '../css/payment.css';
import { IoIosClose } from "react-icons/io";
import axios from 'axios';
import { useNavigate, Link } from 'react-router-dom';
import { clearOrderByWebId1 } from '../redux/store/order'
import { setpayflag1 } from '../redux/store/payflag'
import { setuserdata1 } from '../redux/store/userdata';
import { setcardid1 } from '../redux/store/cardid';

function PaymentInfo({ open, onClose, totalSum, order }) {
    const order2 = useSelector((state) => state.setOrder.order);
    const [webOrder, setWebOrder] = useState([])
    const dispatch = useDispatch()
    const navigate = useNavigate();
    const web = useSelector((state) => state.setWeb.web.web);
    const cardid = useSelector((state) => state.setcardid.cardid);
    const userdata = useSelector((state) => state.setuserdata.userdata);
    const paymentorder = useSelector((state) => state.setpaymentorder.paymentorder);
    const [webAlertFlag, setWebAlertFlag] = useState(false)
    const [city, setCity] = useState([])
    const [street, setStreet] = useState([])
    const [selectedCity, setSelectedCity] = useState([]);
    const [selectedStreet, setSelectedStreet] = useState("");
    const [delivery, setDelivery] = useState(0)
    const [selectedOption, setSelectedOption] = useState(null);
    const [name, setName] = useState('')
    const [lastName, setLastName] = useState('')
    const [phone, setPhone] = useState('')
    const [email, setEmail] = useState('')
    const [payment, setPayment] = useState('')
    const [paymentFlag, setPaymentFlag] = useState(true)
    const [userCode, setUserCode] = useState(0)
    const [code, setCode] = useState(0)
    const [note, setNote] = useState('')
    const [completeFlag, setCompleteFlag] = useState(false)
    const [homeNumber, setHomeNumber] = useState("")
    const [buildingNumber, setBuildingNumber] = useState("")
    const [orderNumber, setOrderNumber] = useState(0)
    const [refusal, setRefusal] = useState(false)
    const userOrder = JSON.parse(sessionStorage.getItem("order"));
    const LowProfileId = JSON.parse(sessionStorage.getItem("LowProfileId"));
    const [codeFlag, setCodeFlag] = useState(false)
    const [terms, setTerms] = useState(false)
    const [termsFlag, setTermsFlag] = useState(false)
    const onClose1 = () => {
        setRefusal(false)
        setPaymentFlag(true)
        setCompleteFlag(false)
        onClose()
    }

    const handleTerms = (event) => {
        setTerms(!terms)
    }

    const checkCode = async () => {
        if (code === userCode) {

            if (payment === "מזומן בעט מסירה ") {
                dispatch(setcardid1("false"))
                //full date
                const currentDate = new Date();
                const day = currentDate.getDate();
                const month = currentDate.getMonth() + 1; // Note: January is 0
                const year = currentDate.getFullYear();
                const formattedDate1 = `${day}/${month}/${year}`; // Using forward slashes
                //----------------

                const product = order.flatMap((item) => {
                    const allProduct = [];
                    if (item.count > 0) {
                        for (let i = 0; i < item.count; i++) {
                            const filterPrice = web.eilat ? item.eilatPrice : item.price
                            const filterONslaePrice = web.eilat ? item.onSalePriceEilatPrice : item.onSalePrice
                            allProduct.push({ name: item.name, price: filterONslaePrice > 0 ? filterONslaePrice : filterPrice, image: item.mainImage });
                        }
                        if (selectedOption === "משלוח") {

                            allProduct.push({ name: "משלוח", price: web.city === selectedCity ? web.deliver.city : web.deliver.outCity })
                        }

                    }

                    return allProduct;
                });
                const data = [{ orderItems: product, orderDate: formattedDate1, orderNumber: 0, orderStatus: "ממתין", orderTotal: totalSum, orderPayment: payment, clientDetails: { name: name, email: email, phone: phone, address: selectedStreet, city: selectedCity, homeNumber: homeNumber, buildingNumber: buildingNumber }, webID: web._id, note: note.length > 0 ? note : "אין הערות", delivery: selectedOption }, { phone: web.accessibility.phone }]
                const url = "https://ryc-sale-ryc-server-sale.emzsne.easypanel.host/order"
                const order1 = await axios.post(url, data)
                setCompleteFlag(true)
                setOrderNumber(order1.data.orderNumber)
                dispatch(clearOrderByWebId1({ id: web._id }))

                return
            } else {

                const product = order.flatMap((item) => {
                    const allProduct = [];
                    if (item.count > 0) {
                        for (let i = 0; i < item.count; i++) {
                            const vat =web.eilat && selectedCity === "אילת" ? false : web.eilat && selectedOption === "איסוף מהחנות" ? false : true
                            const filterPrice = !vat ? item.eilatPrice : item.price
                            const filterONslaePrice = !vat ? item.onSalePriceEilatPrice : item.onSalePrice
                            allProduct.push({ name: item.name, price: filterONslaePrice > 0 ? filterONslaePrice : filterPrice });
                        }

                    }
                    if (selectedOption === "משלוח") {

                        allProduct.push({ name: "משלוח", price: web.city === selectedCity ? web.deliver.city : web.deliver.outCity })
                    }
                    return allProduct;
                });
                const url = "https://ryc-sale-ryc-server-sale.emzsne.easypanel.host/payment"
                const Filterproducts = product.map((item) => {
                    const vat =web.eilat && selectedCity === "אילת" ? false : web.eilat && selectedOption === "איסוף מהחנות" ? false : true
                    const filterPrice = !vat ? item.eilatPrice : item.price
                    const filterONslaePrice = !vat ? item.onSalePriceEilatPrice : item.onSalePrice
                    return {
                        name: item.name,
                        price: item.price,

                    }
                })
                const totalsum1 =Filterproducts.reduce((total, item) => total + item.price, 0)
                const totalAmount = web.city === selectedCity ? web.deliver.city : web.deliver.outCity
                const data = {
                    terminalNumber: web.payment.terminal,
                    apiName: web.payment.name,
                    amount:  totalsum1,
                    products: Filterproducts,
                    email: email,
                    webName: web.name,
                    vat: web.eilat && selectedCity === "אילת" ? false : web.eilat && selectedOption === "איסוף מהחנות" ? false : true,
                }
                const order1 = await axios.post(url, data)
                sessionStorage.setItem("LowProfileId", JSON.stringify(order1.data.LowProfileId));

                dispatch(setcardid1(order1.data.LowProfileId))
                window.location.href = `${order1.data.Url}`
              

                return
            }

        } else {
            setCodeFlag(true)

            setTimeout(() => {
                setCodeFlag(false)
            }, 3000);
        }
    }

    // const cleardata = () => {
    //     dispatch(setcardid1("false"))
    //     dispatch(clearOrderByWebId1({ id: web._id }))
    //     setPaymentFlag(false)
    //     setCompleteFlag(true)
    //     dispatch(setpayflag1(true))
    //     const clear = []
    //     dispatch(setuserdata1(clear))
    // }

    const checkPaymnet = async () => {
        const url2 = "https://secure.cardcom.solutions/api/v11/LowProfile/GetLpResult"
        const data2 = {
            TerminalNumber: web.payment.terminal,
            ApiName: web.payment.name,
            LowProfileId: LowProfileId
        }
        const order2 = await axios.post(url2, data2)

        if (order2.data.Description === "העסקה בוצעה בהצלחה") {
            dispatch(clearOrderByWebId1({ id: web._id }))

            const url = "https://ryc-sale-ryc-server-sale.emzsne.easypanel.host/order"
            const order1 = await axios.post(url, userOrder)
            setOrderNumber(order1.data.orderNumber)
            dispatch(clearOrderByWebId1({ id: web._id }))
            setPaymentFlag(false)
            setCompleteFlag(true)
            dispatch(setpayflag1(true))

            sessionStorage.removeItem("order");
            sessionStorage.removeItem("LowProfileId");

            return
        }
        sessionStorage.removeItem("order");
        sessionStorage.removeItem("LowProfileId");
        setRefusal(true)
        setPaymentFlag(false)
        setCompleteFlag(true)
        dispatch(setpayflag1(true))

    }

    const sendSms = async (adminPhone) => {
        try {

            const url = "https://ryc-sale-ryc-server-sale.emzsne.easypanel.host/order/check"
            const randomNum = Math.floor(234 + Math.random() * 965)
            let data = {
                name: web.name,
                phone: phone,
                randomNum: randomNum
            }
            const order = await axios.post(url, data)
            setCode(randomNum)

        }
        catch (error) {
            console.log(error);
        }

    }

    const paymentHandle = async () => {
        if (!terms) {
            setTermsFlag(true)
            setTimeout(() => {
                setTermsFlag(false)
            }, 2000);
            return
        }

        if (name.length === 0 || lastName.length === 0 || phone.length === 0 || email.length === 0 || selectedCity.length === 0 || selectedStreet.length === 0 || homeNumber.length === 0 || buildingNumber.length === 0 || selectedOption === null || payment.length === 0) {
            alert("אנא מלא את כל השדות")
            return
        }
        const currentDate = new Date();
        const day = currentDate.getDate();
        const month = currentDate.getMonth() + 1;
        const year = currentDate.getFullYear();
        const formattedDate1 = `${day}/${month}/${year}`;
        const product = order.flatMap((item) => {
            const allProduct = [];
            if (item.count > 0) {
                for (let i = 0; i < item.count; i++) {
                    const filterPrice = web.eilat ? item.eilatPrice : item.price
                    const filterONslaePrice = web.eilat ? item.onSalePriceEilatPrice : item.onSalePrice
                    allProduct.push({ name: item.name, price: filterONslaePrice > 0 ? filterONslaePrice : filterPrice, image: item.mainImage });
                }
                if (selectedOption === "משלוח") {

                    allProduct.push({ name: "משלוח", price: web.city === selectedCity ? web.deliver.city : web.deliver.outCity })
                }

            }

            return allProduct;
        });
        const data = [{ orderItems: product, orderDate: formattedDate1, orderNumber: 0, orderStatus: "ממתין", orderTotal: totalSum, orderPayment: payment, clientDetails: { name: name, email: email, phone: phone, address: selectedStreet, city: selectedCity, homeNumber: homeNumber, buildingNumber: buildingNumber }, webID: web._id, note: note.length > 0 ? note : "אין הערות", delivery: selectedOption }, { phone: web.accessibility.phone }]
        const dataDelivery = [{ orderItems: product, orderDate: formattedDate1, orderNumber: 0, orderStatus: "ממתין", orderTotal: totalSum, orderPayment: payment, clientDetails: { name: name, email: email, phone: phone, address: selectedStreet, city: selectedCity, homeNumber: homeNumber, buildingNumber: buildingNumber }, webID: web._id, note: note.length > 0 ? note : "אין הערות", delivery: selectedOption }, { phone: web.accessibility.phone }]
        console.log(data);
        sessionStorage.setItem("order", JSON.stringify(data));
        sendSms()
        setPaymentFlag(false)
    }

    const handlePayment = (event) => {
        setPayment(event.target.value)
    }

    const handleOptionChange = (event) => {
        setSelectedOption(event.target.value);

    };

    const handleSTREETChange = (event) => {
        setSelectedStreet(event.target.value);
    }

    const handleCityChange = (event) => {
        setSelectedCity(event.target.value);

    };



    const getStreets = async (city) => {
        var data = {
            resource_id: 'a7296d1a-f8c9-4b70-96c2-6ebb4352f8e3', // the resource id
            limit: 10000, // get 5 results
            q: city
        };
        await axios.get('https://data.gov.il/api/3/action/datastore_search', { params: data }).then(function (response) {

            setStreet(response.data.result.records)
        }
        ).catch(function (error) {
            console.log(error);
        });
    }

    const getCity = async () => {
        var data = {
            resource_id: 'd4901968-dad3-4845-a9b0-a57d027f11ab', // the resource id
            limit: 1273, // get 5 results

        };
        await axios.get('https://data.gov.il/api/3/action/datastore_search', { params: data }).then(function (response) {
            setCity(response.data.result.records)
        }
        ).catch(function (error) {
            console.log(error);
        });
    }

    useEffect(() => {
        if (!web.deliver.deliverOutCity) {
            setSelectedCity(web.city)
            getStreets(web.city)
            return
        }
        if (!selectedCity) return
        const findCity = city.find((city) => city.שם_ישוב.trimEnd().includes(selectedCity))
        if (!findCity) return
        getStreets(findCity.שם_ישוב)
    }, [selectedCity])

    useEffect(() => {
        setSelectedCity(web.city)
        getCity()
    }, [])

    useEffect(() => {

        if (LowProfileId) {

            checkPaymnet()
            return
        }
        return

    }, [web])




    if (!open) return null
    return (
        <div>
            {web ?
                <div onClick={onClose} className='overlay1'>
                    <div onClick={(e) => { e.stopPropagation() }} className="modalContainer1">
                        <div className="modalRight1">
                            <div className='closeBtn1'>

                                <IoIosClose size={30} onClick={onClose1} />
                            </div>
                            {paymentFlag ?
                                !completeFlag ?
                                    <div className='payment-container-name'>
                                        <div className="content1">
                                            <div className="inputGroup">
                                                <label htmlFor="firstName">שם:</label>
                                                <input value={name} onChange={(e) => setName(e.target.value)} id="firstName" type="text" />
                                            </div>

                                            <div className="inputGroup">
                                                <label htmlFor="lastName">שם משפחה:</label>
                                                <input value={lastName} onChange={(e) => setLastName(e.target.value)} id="lastName" type="text" />
                                            </div>
                                            <div className="inputGroup">
                                                <label htmlFor="phone">טלפון:</label>
                                                <input value={phone} onChange={(e) => setPhone(e.target.value)} id="phone" type="text" />
                                            </div>
                                            <div className="inputGroup">
                                                <label htmlFor="email">אימייל:</label>
                                                <input value={email} onChange={(e) => setEmail(e.target.value)} id="email" type="email" />
                                            </div>
                                        </div>


                                        <div className="content1" >
                                            <div className="inputGroup">
                                                <label htmlFor="firstName">עיר:</label>
                                                <form action="">
                                                    <input list='city1' onChange={handleCityChange} name="" id="" />
                                                    <datalist id='city1'>
                                                        {web.deliver.deliverOutCity ? city.map((city, index) => {
                                                            return (
                                                                <option key={index} value={city.שם_ישוב.trimEnd()}></option>
                                                            )
                                                        }) :
                                                            <option value={web.city.trimEnd()}></option>

                                                        }
                                                    </datalist>
                                                </form>
                                            </div>

                                            <div className="content1" style={{ marginLeft: "60px" }}>
                                                <div className="inputGroup">
                                                    <label htmlFor="firstName">רחוב:</label>
                                                    <form action="">
                                                        <input list='street1' onChange={handleSTREETChange} name="" id="" />
                                                        <datalist id='street1'>
                                                            {street.length > 0 ? street.map((city, index) => {
                                                                return (
                                                                    <option key={index} value={city.שם_רחוב.trimEnd()}></option>
                                                                )
                                                            }) : null}
                                                        </datalist>
                                                    </form>
                                                    <div style={{ display: "flex", justifyContent: "center" }}>
                                                        <div style={{ paddingLeft: "20px" }}>
                                                            <label htmlFor="firstName">מספר בניין:</label>
                                                            <input value={buildingNumber} onChange={(e) => setBuildingNumber(e.target.value)} style={{ width: "40px" }} type="text" name="" id="" />
                                                        </div>
                                                        <div>
                                                            <label htmlFor="firstName">מספר בית:</label>
                                                            <input value={homeNumber} onChange={(e) => setHomeNumber(e.target.value)} style={{ width: "40px" }} type="text" name="" id="" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <h3 className='payment-h3'>בחרית דרך איסוף</h3> <br />
                                            <div className="inputGroup">
                                                <div style={{ display: "flex", justifyContent: "start" }}>
                                                    <input checked={selectedOption === "משלוח"} onChange={handleOptionChange} value={"משלוח"} style={{ width: "25px" }} name='a' type="radio" /> &nbsp; &nbsp;
                                                    <label style={{ fontSize: "14px" }} htmlFor="lastName">  משלוח ב{selectedCity.length>1? selectedCity:web.city} ( {web.city === "אילת" ? "ללא מע״מ-" : null}  עלות משתנה בהתאם להזמנה): ₪{web.city === selectedCity ? web.deliver.city : web.deliver.outCity}</label>
                                                </div>
                                            </div>

                                            <div className="inputGroup">
                                                <div style={{ display: "flex", justifyContent: "start" }}>
                                                    <input checked={selectedOption === "איסוף מהחנות"} onChange={handleOptionChange} value={"איסוף מהחנות"} style={{ width: "25px" }} name='a' type="radio" /> &nbsp; &nbsp;
                                                    <label style={{ fontSize: "14px" }} htmlFor="lastName">איסוף מהחנות </label>
                                                </div>
                                            </div> <br />

                                            <h3 className='payment-h3'>  אמצעי תשלום</h3> <br />

                                            <div className="inputGroup2">
                                                <div style={{ display: "flex", justifyContent: "start" }}>
                                                    <input checked={payment === "מזומן בעט מסירה "} onChange={handlePayment} value={"מזומן בעט מסירה "} style={{ width: "25px" }} name='b' type="radio" /> &nbsp; &nbsp;
                                                    <label style={{ fontSize: "14px" }} htmlFor="lastName">מזומן בעט מסירה  </label>
                                                </div>
                                            </div> <br />
                                            {web.payment.payment ?
                                                <div className="inputGroup2">
                                                    <div style={{ display: "flex", justifyContent: "start" }}>
                                                        <input checked={payment === "כרטיס אשראי(תשלום מאובטח"} onChange={handlePayment} value={"כרטיס אשראי(תשלום מאובטח"} style={{ width: "25px" }} name='b' type="radio" /> &nbsp; &nbsp;
                                                        <label style={{ fontSize: "14px" }} htmlFor="lastName">כרטיס אשראי(תשלום מאובטח) </label>
                                                    </div> <br />
                                                </div> : null
                                            }
                                            <div className="inputGroup2">
                                                <div style={{ display: "flex", justifyContent: "start", marginTop: "2px" }}>
                                                    <input checked={terms === true} onChange={handleTerms} value={"איושר תקנון"} style={{ width: "20px" }} name='aa' type="checkbox" /> &nbsp; &nbsp;
                                                    <Link to={`/${web.name}/terms`}>  <label style={{ fontSize: termsFlag ? "14px" : "13px", color: termsFlag ? "red" : 'black' }} htmlFor="lastName">קראתי ואני מסכים לאתר תנאי שימוש/תקנון </label></Link>
                                                </div>
                                            </div>
                                        </div>

                                    </div> : null
                                : null

                            }


                            {!completeFlag ?
                                <div className='payment-bt-contianer'>
                                    {paymentFlag ?
                                        <button onClick={paymentHandle} className='payment-bt'>שליחת הזמנה</button> :
                                        <div className="inputGroup2" >
                                            <label className='payment-code' htmlFor="lastName"> קוד אימות  </label>

                                            <input onChange={(e) => setUserCode(+e.target.value)} style={{ height: "25px" }} type="text" />
                                            <button className='payment-checkPhone' onClick={checkCode}  >אישור טלפון</button>
                                            {codeFlag ?
                                                <h3 style={{ textAlign: "center", marginTop: "8px", color: "red" }}>הקוד שהוזן שגוי, יש לבדוק את מספר הטלפון</h3> : null
                                            }
                                            <div style={{ display: "flex", justifyContent: "center", marginTop: "40px" }}>
                                                <button onClick={sendSms}>לא קיבלתי,שלח שוב</button>
                                                <div style={{ marginRight: "50px" }}>

                                                    <button onClick={() => setPaymentFlag(true)}>חזרה</button>
                                                </div>
                                            </div>
                                        </div>
                                    }
                                </div> :
                                <div className='payment-bt-contianer'>
                                    {!refusal ?
                                        <h1>ההזמנה התקבלה בהצלחה, מספר הזמנה:{orderNumber}</h1> :
                                        <h1>ההזמנה נכשלה</h1>
                                    }

                                </div>
                            }


                        </div>
                    </div>
                </div>
                : null}

        </div>
    );
}


export default PaymentInfo;