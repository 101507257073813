import React, { useEffect } from 'react';
import Loading from '../Loading';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { setaaa1 } from '../redux/store/aaa.js';


function CheckUrl(props) {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { name: name } = useParams()
    const { branch: branch } = useParams()
    const { category: category } = useParams()

    useEffect(() => {
        if (category === "none") {
            const sessionStorage = window.sessionStorage.setItem("check", true)
            const sessionStorage1 = window.sessionStorage.setItem("branch", branch)
            dispatch(setaaa1(true))
            navigate(`/${name}`, { replace: true });
            return
        }
        const sessionStorage = window.sessionStorage.setItem("check", true)
        const sessionStorage1 = window.sessionStorage.setItem("branch", branch)
        dispatch(setaaa1(true))
        navigate(`/${name}/products/${category}`, { replace: true });
        return
    }, [])

    return (
        <div>
            <Loading />
        </div>
    );
}

export default CheckUrl;