import React, { useState, useEffect } from 'react';
import "../../css/productSelects.css"
import { FaHotjar } from "react-icons/fa";
import { useDispatch, useSelector } from 'react-redux';
import { setorder1 } from '../../redux/store/order';
import { useParams, useNavigate } from 'react-router-dom';
import { FaHeart } from "react-icons/fa";
import { FaRegHeart } from "react-icons/fa";
import { deleteorder1 } from '../../redux/store/order';


function ProductSelectCard(props) {
    const web = useSelector((state) => state.setWeb.web.web);
    const order = useSelector((state) => state.setOrder.order);
    const filterOrder = order.filter(item => item.webID === web._id)
    const products = useSelector((state) => state.setProducts.products);
    const webOrder =order.filter(item=>item.webID===web._id)

    const width = window.innerWidth;
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { category } = useParams();
    const [heart, setHeart] = useState(false);

    const checkProductInOrder = (id) => {
        const check = filterOrder.find(item => item._id === id)

        if (check) {
            return true
        }
    }

    const deleteOrder = () => {
        const findeProduct = webOrder.find(item => item._id === props.product._id)
        dispatch(deleteorder1({ index: findeProduct}))
      }
    


    const addToOrder = (event) => {
        event.stopPropagation(); // Stop the click event propagation
        const random = Math.floor(Math.random() * 10000) + 1022;
        const newProduct = { ...props.product };
        newProduct["count"] = 1;
        newProduct["id"] = random;
        dispatch(setorder1({ order: newProduct }))

    }
    //     {props.product.imageUrl ?
    //         <img className='img1' src={props.product.imageUrl} alt="" />
    //         :

    //         <img className='img1' src={`https://ryc-sale-ryc-imageserver-sale.emzsne.easypanel.host/images/${props.product.mainImage}`} alt="" />
    //     }
    //     <div style={{ alignSelf: "center", marginBottom: "4px" }}>
    //     {checkProductInOrder(props.product._id) ?
    //         <FaHeart color="red" onClick={addToOrder} size={20} />
    //         : <FaRegHeart size={20} onClick={addToOrder} />


    //     }
    // </div>
    {/* <span style={{ color: "red" }}> ₪{props.product.onSalePrice} </span> &nbsp; &nbsp; <del style={{ fontSize: "14px" }}> ₪{props.product.price}</del> */ }

    return (

        <div className='products-template-container' >
            <div>

                {props.product.imageUrl ?
                    <img className='img1' src={props.product.imageUrl} alt="" />
                    :

                    <img className='img1' src={`https://ryc-sale-ryc-imageserver-sale.emzsne.easypanel.host/images/${props.product.mainImage}`} alt="" />
                }
                <p style={{textAlign:"center", fontSize:"10px",marginTop:"5px",marginRight:"5px"}}>*התמונה להמחשה בלבד</p>
            </div>
            <div className='products-template-info'>

                <h3 className='products-template-name'>{props.product.name}</h3>
                <p style={{ marginTop: "20px", fontSize: "14px", fontFamily: "sans-serif", fontWeight: "bold", marginLeft: "20px" }}>
                    {props.product.longDescription}
                </p>
                <div style={{ marginTop: "40px", marginBottom: "10px" }}>
                    <span style={{ color: "red" }}><del style={{ fontSize: "14px" }}> ₪{props.product.price}</del></span> &nbsp; &nbsp; ₪{props.product.onSalePrice}

                    {checkProductInOrder(props.product._id) ?
                        <FaHeart style={{ marginRight: "70px" }} color="red" onClick={deleteOrder} size={20} />
                        : <FaRegHeart style={{ marginRight: "70px" }} size={20} onClick={addToOrder} />


                    }

                </div>
            </div>


        </div>

    );
}

export default ProductSelectCard;