import React, { useEffect,useState } from 'react';
import { NavLink, useLocation, useNavigate,Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import '../css/navBar1.css';


function NavBar1() {
    const location = useLocation();
    const web = useSelector((state) => state.setWeb.web.web);
    

    const isActive = (path) => {
       
        return location.pathname.startsWith(path);
    };

    const isActive1 = (path) => {
       if(location.pathname === path){
           return true
         }
        
    };
   

    return (
        <>
        {web? 
        <div className="navbar111">
            {web.navBar.map((item, index) => {
                return (
                    <NavLink
                        key={index}
                        to={`/${web.name}/products/${item}`}
                        className={isActive(`/${web.name}/products/${item}`) ? 'active' : ''}
                        >
                        <p>{item}</p>
                    </NavLink>
                );
            })}
           
            <Link
                to={`/${web.name}/products`}
                className={isActive1(`/${web.name}/products`) ? 'active' : ''}
                >
                <p> קטגוריות</p>
            </Link>
            
        </div>
        : null}
                </>
    );
}

export default NavBar1;
