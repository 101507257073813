import React from 'react';
import { useSelector } from 'react-redux';
import { Helmet } from 'react-helmet';
function Terms(props) {

    const web = useSelector((state) => state.setWeb.web.web);


    return (
        <div>
             <Helmet>
                    <meta charSet="utf-8" />
                    <title>{web.name}- תקנון</title>
                    <link rel="icon" href={`https://ryc-sale-ryc-imageserver-sale.emzsne.easypanel.host/images/${web.logo}`} />
                </Helmet>
            {web ?
            
                <div style={{ display: "flex", justifyContent: "center",alignContent:"center",margin:"0 auto",width:"70%" }}>
                    
                    <div style={{justifyContent:"center",}}>


                        <h1 style={{ fontWeight: "bold",textAlign:"center" }}>
                            תקנון, תנאי שימוש ומדיניות פרטיות לאתר <br /> ״{web.name}״

                        </h1> <br />
                        {web.name}    מברכת את בחירתכם לגלוש באתר האינטרנט שלנו בכתובת: www.ryc.co.il/{web.name} (״האתר״). <br />

                        {web.name} הינה חנות מקוונת לרכישה קלה ובטוחה  (“המוצרים”).<br /> בנוסף, האתר מספק מידע אודות המוצרים הייחודיים המוצעים לרכישה באתר, מחירי המוצרים ומדיניות המשלוחים. <br /> <br />

                        <h3 style={{ textDecorationLine: "underline" }}>1. השימוש באתר:</h3> <br />
                        &#x2022;  תקנון ותנאי שימוש אלה מהווים הסכם מחייב בינך, בין אם באופן פרטי ובין באמצעות תאגיד (״אתה״) לבין האתר בנוגע לגלישה ושימוש באתר בכל מחשב או מכשיר תקשורת אחר כדוגמת טלפון סלולרי, מחשבי טאבלט וכיו”ב. כמו כן הם חלים על השימוש באתר ו/או באתרים קשורים באמצעות אפליקציה או כל אמצעי אחר. קרא את התקנון ותנאי השימוש בקפידה, שכן הגלישה באתר וביצוע פעולות בו מעידים על הסכמתך לתנאים הכלולים בתקנון ותנאי שימוש אלו (״התקנון״). <br />
                        &#x2022;  האמור בתקנון מתייחס באופן שווה לבני שני המינים והשימוש בלשון זכר ו/או נקבה הוא מטעמי נוחות בלבד.<br />
                        &#x2022;  הגלישה באתר מותרת בכל גיל. הרכישה באתר מיועדת לבעלי כשירות משפטית מגיל 18 בלבד. רכישה של קטין מתחת לגיל 18 באתר מחייבת אישור הורה או אפוטרופוס.< br /> &#x2022;
                        ככלל, הגלישה באתר והעיון בו אינם דורשים הרשמה, והוא פתוח לכל גולש. מבלי לגרוע מן האמור לעיל, האתר שומר על זכותו למנוע מגולש אשר לא נרשם לאתר גישה לשירותים מסוימים באתר, מכל סיבה או נימוק שהוא, בשיקול דעתו הבלעדי, ללא צורך בהודעה מוקדמת או התראה ומבלי שיהיה אחראי לנזק כלשהו עקב החלטתו.<br />
                        &#x2022;  במקרה שייקבע כי הוראה בתקנון זה אינה ניתנת לאכיפה או שהינה חסרת תוקף מטעם כלשהו, לא יהא בכך כדי להשפיע או לפגוע בחוקיותן, תקפותן ואכיפתן של שאר הוראות התקנון.<br /> <br />

                        <h3 style={{ textDecorationLine: "underline" }}>2. קניין רוחני:</h3> <br />
                        &#x2022;  אלא אם צוין במפורש אחרת, כל זכויות היוצרים והקניין הרוחני באתר, לרבות, עיצובים, תמונות, שמע, וידאו, טקסט, מאגרי מידע, תוכנות,<br /> קוד (״התוכן״) וכן סמלילים, סימני מסחר וכיו״ב (״הסימנים״) הם בבעלות האתר בלבד, או בבעלות צד שלישי, שהרשה לאתר או נתן רישיון לאתר לעשות שימוש על פי דין בתכנים או בסימנים ובכלל זה שותפיו העסקיים של האתר.
                        <br />&#x2022; התוכן והסימנים ניתנים כמו שהם ״AS IS״ לשימוש אישי בלבד. אלא אם צוין במפורש אחרת, אין להעתיק, להפיץ, להציג בפומבי, לבצע בפומבי, להעביר לציבור, לשנות, לעבד, ליצור יצירות נגזרות, למכור או להשכיר כל חלק מן הנ”ל, בין ישירות ובין באמצעות או בשיתוף צד שלישי, בכל דרך או אמצעי בין אם אלקטרוניים, מכאניים, אופטיים, אמצעי צילום או הקלטה, או בכל אמצעי ודרך אחרת, בלא קבלת הסכמה בכתב ומראש מהאתר או מבעלי הזכויות האחרים, לפי העניין, ובכפוף לתנאי ההסכמה.
                        <br />&#x2022; אם וככל שניתנה הסכמה כאמור, יש להימנע מלהסיר, למחוק או לשבש כל הודעה או סימן בעניין זכויות קניין רוחני, לדוגמה: סימון זכויות היוצרים ,© או סימן מסחר ®, הנלווים לתכנים שיעשה בהם שימוש.
                        <br />&#x2022; בתנאי שאתה זכאי לגלוש באתר, האתר מקנה לך רישיון מוגבל לשימוש בו ולהורדת או הדפסת עותק של כל חלק מהתוכן אליו ניתנה גישה ראויה לשימוש אישי בלבד, שאינו מסחרי. <br /> <br />


                        <h3 style={{ textDecorationLine: "underline" }}>3. הפרת זכויות יוצרים וקניין רוחני:</h3> <br />
                        &#x2022; אנו מכבדים את זכויות היוצרים והקניין של אחרים. אם אתה מאמין כי מידע או תוכן באתר מפר את זכויות קנייניות השייכות לך, אנא צור קשר באמצעות פרטי ההתקשרות שבתחתית תקנון זה. <br />


                        <br /> <h3 style={{ textDecorationLine: "underline" }}>4. הצהרות משתמש:</h3> <br />
                        &#x2022; בעת גלישה באתר, אתה מתחייב ומצהיר כדלקמן: (1) הגלישה באתר והשימוש הם באחריותך הבלעדית; (2) הפרטים שהזנת בעת השארת הפרטים ו/או רכישה באתר הם נכונים, עדכניים, מדיוקים ומלאים; (3) ככל ויהיה בכך צורך, במקרה של שינוי פרטים תעדכנם בפנייה לאתר; (4) הנך בעל כשירות משפטית ואתה מסכים לתנאי התקנון; (5) לא תעשה שימוש באתר באמצעים אוטומטיים או לא אנושיים, בין אם באמצעות BOT, סקריפט או בכל דרך אחרת; (6) לא תעשה שימוש בלתי חוקי באתר; (7) השימוש שלך באתר לא יפר כל חוק או תקנה רלוונטיים.
                        <br /> &#x2022; האתר רשאי, למנוע מכל גולש שימוש באתר לפי שיקול דעתו המוחלט. מבלי לגרוע מהאמור לעיל, האתר רשאי לחסום גישתו אליו או חלקה אם בעת השארת פרטים באתר נמסרו במתכוון פרטים שגויים, לא עדכניים או לא מלאים. <br /> <br />


                        <h3 style={{ textDecorationLine: "underline" }}>5. פעילות אסורה באתר:</h3> <br />
                        &#x2022; אינך רשאי להשתמש באתר אלא למטרות שלשמן הוא נועד. השימוש באתר מותר למטרות פרטיות ואישיות בלבד ואין לעשות בו שימוש למטרות מסחריות למעט כאלו שיאושרו על ידי האתר באופן ספציפי. <br />
                        &#x2022; כמשתמש האתר, אתה מסכים שלא: <br />
                        &nbsp; -לאחזר נתונים או תוכן אחר מהאתר כדי ליצור או להרכיב אוסף, מסד נתונים או מדריך ללא אישור מראש ובכתב מהאתר; <br />
                        &nbsp; -לעשות כל שימוש בעיצובי האתר; <br />

                        &nbsp; -לעשות שימוש לא מורשה באתר, לרבות איסוף כתובות דוא”ל וכיו״ב באמצעים אלקטרוניים או אחרים לצורך שליחת דוא”ל באמצעים אוטומטיים; <br />
                        &nbsp; -לעקוף, להשבית או להפריע בדרך אחרת לאבטחת האתר, לרבות שימוש ביישומים המונעים או מגבילים את השימוש או ההעתקה של תוכן כלשהו;<br />
                        &nbsp; -להונות או להטעות את האתר ו/או את משתמשיו;<br />
                        &nbsp; -לעשות שימוש לא נכון בשירותי התמיכה של האתר או להגיש דוחות כוזבים בנוגע לשימוש באתר; <br />
                        &nbsp; -לעשות שימוש אוטומטי במערכת, כגון שימוש בסקריפטים לשליחת הערות או הודעות, או שימוש בכריית נתונים, רובוטים או כלי איסוף וחילוץ נתונים דומים;<br />
                        &nbsp; -לנסות להתחזות לאדם אחר ו/או להעביר את פרטי הגישה לאתר לאדם אחר;<br />
                        &nbsp; -להשתמש במידע שהתקבל באתר על מנת להטריד, להתעלל או לפגוע באדם אחר;<br />
                        &nbsp; -להשתמש באתר כחלק מכל מאמץ להתחרות באתר;<br />
                        &nbsp; -לאחזר, לפענח או להנדס לאחור חלק מהאתר, אפשרות באתר או יישום באתר;<br />
                        &nbsp; -להטריד, להפחיד או לאיים על כל אחד מעובדי או סוכני האתר;<br />
                        &nbsp; -למחוק את זכויות היוצרים או את הודעת הזכויות הקנייניות מכל תוכן או סימן;<br />
                        &nbsp; -להעתיק או להתאים את  קוד האתר או חלק ממנו, כולל אך לא רק HTML ,Java Script PHP ,CSS , JSON או קוד אחר; <br />
                        &nbsp; -להעלות או להעביר (או לנסות להעלות או להעביר) וירוסים, סוסים טרויאניים, או חומר אחר, כולל שימוש בדואר זבל, אשר יפריע לשימוש באתר;<br />
                        &nbsp; -לבצע פעולה שתפגע או תזיק לאתר, בהתאם לשיקול דעתו של האתר;<br />
                        &nbsp; -להשתמש באתר באופן שאינו עולה בקנה אחד החוק, התקנות והפסיקה. <br />
                        &#x2022; מבלי לגרוע מכל זכות נוספת, כל שימוש באתר תוך הפרה של האמור לעיל עלול לגרום, בין היתר, לסיום או השעיית זכויותיך לשימוש באתר. <br /> <br />


                        <h3 style={{ textDecorationLine: "underline" }}>6. תוכן צדדים שלישיים:</h3> <br />
                        &#x2022; האתר רשאי להשתמש בקישורים לאתרים אחרים (“אתרי צד שלישי“) וכן במאמרים, תמונות, טקסט, גרפיקה, תמונות, עיצובים, מוסיקה, סאונד, וידאו, מידע, אפליקציות, תוכנה ותכנים או פריטים אחרים השייכים או שמקורם באתרי צד שלישי (“תוכן של צד שלישי”). אתרי צד שלישי ותוכן של צד שלישי אינם נחקרים, מנוטרים, או נבדקים על ידי האתר והאתר לא אחראי לתוכן של צד שלישי שפורסם דרך האתר, שהגעת אליו דרך האתר, שזמין דרך האתר או מותקן בו, כולל תוכן, חוות דעת, אמינות, נוהלי פרטיות או מדיניות אחרת של או כלולים באתרי שלישי או בתוכן של צד שלישי. שימוש בקישור או התרת שימוש או התקנה של אתרים של צד שלישי או כל תוכן של צד שלישי כלשהו אינה מרמזת על אישור או אישור לכך על ידינו, והאחריות על כניסה לקישור זה היא על הגולש בלבד, ועליו לבדוק אותו טרם הכניסה אליו.
                        <br />&#x2022; האתר לא לוקח אחריות על כל רכישה שתבצע מאתרי צד שלישי או מחברות אחרות אשר יבוצעו ויהיו בתוקף באופן בלעדי בינך לבין הצד השלישי הרלוונטי. <br /> <br />


                        <h3 style={{ textDecorationLine: "underline" }}>7. ניהול האתר:</h3>
                        &#x2022; אנו שומרים לעצמנו את הזכות: (1) לפקח על הפרות של תקנון זה; (2) לנקוט בפעולה משפטית כנגד כל מי שמפר את הוראות החוק או הוראות תקנון זה, לשיקול דעתו הבלעדי של האתר, לרבות, ללא הגבלה, דיווח על המשתמש לרשויות אכיפת החוק; (3) לסרב, להגביל גישה, להגביל זמינות, או להשבית (ככל שניתן מבחינה טכנולוגית) כל תרומה שלך לאתר או כל חלק ממנה, לפי שיקול דעתו הבלעדי של האתר וללא הגבלה; וכן (4) לנהל את האתר באופן שיגן על זכויותיו ורכושו ויקל על תפקודו התקין.  <br /> <br />



                        <h3 style={{ textDecorationLine: "underline" }}>8. הרשאה לדיוור, פרסומים ופרסומת:</h3>
                        &#x2022; גולש שהשאיר פרטים באתר ומצורף לרשימת הדיוור של האתר, מאשר שימוש בפרטיו לצורך קבלת מידע שיווקי, עדכונים ופרסומות שיבצע האתר מעת לעת. <br />
                        &#x2022; על גולש שהשאיר פרטים כאמור תחולנה הוראות הדיוור המפורטות בתקנון להלן. <br />
                        &#x2022; אין להשאיר פרטים של אדם אחר באתר שלא בהסכמתו ו/או ללא נוכחותו מול המסך בשעת השארת הפרטים ולאחר שהוסברו לו כל תנאי התקנון. <br />
                        &#x2022; בעת השארת הפרטים יתבקש הגולש למסור פרטים אישיים כגון: שם פרטי, שם משפחה, טלפון וכן כתובת דואר אלקטרוני פעילה (לשיקול דעתו הבלעדי של האתר). מסירת פרטים חלקיים או שגויים עלולים למנוע את האפשרות להשתמש בשירות ולסכל יצירת קשר במקרה הצורך. במקרה של שינוי פרטים יש לעדכנם באתר. <br />
                        &#x2022; מובהר כי אין חובה על-פי חוק למסור פרטים באתר, אולם בלא למוסרם לא ניתן יהיה לקבל תוכן שיווקי ועדכונים מהאתר. <br />
                        &#x2022; האתר לא יעשה בפרטים שנמסרו שימוש, אלא בהתאם למדיניות הפרטיות של האתר המהווה חלק בלתי נפרד מהתקנון. <br />
                        &#x2022; השארת פרטים באתר ואישור לקבלת תוכן שיווקי כוללת, בין היתר, קבלת תוכן שיווקי, מידע ביחס למבצעים, עדכונים והנחות המוצעים למשתמשים רשומים. <br />
                        &#x2022; אישור דיוור (קבלת תוכן שיווקי) כאמור, מהווה את הסכמתו של הגולש למשלוח דברי פרסומת על-פי חוק התקשורת (בזק ושידורים) (תיקון מס’ 40) התשס”ח – 2008 (“חוק התקשורת”). <br />
                        &#x2022; מובהר כי באפשרות משאיר הפרטים להסיר עצמו בכל עת מהדיוור באמצעות לחיצה על “להסרה מרשימת התפוצה” או כל מלל דומה שמופיע בתחתית כל דיוור שנשלח או באמצעות פנייה לאתר בדוא”ל. כל עוד לא הסיר עצמו הנרשם מרשימת הדיוור כאמור, רשאי האתר, בכפוף לחוק התקשורת, להעביר לנרשם דיוור ישיר כאמור. <br />
                        &#x2022; האתר רשאי לבטל את רישומו של גולש לרשימת הדיוור לפי שיקול דעתו המוחלט. מבלי לגרוע מהאמור לעיל, רשאי האתר למנוע גלישת גולש ו/או לבטל רישום לרשימת הדיוור, או לחסום גישה אליו בכל אחד מהמקרים הבאים: <br />
                        &nbsp; -אם בעת השארת הפרטים ו/או הרכישה באתר נמסרו במתכוון פרטים שגויים; <br />
                        &nbsp; -במקרה שנעשה שימוש באתר לביצוע או כדי לנסות לבצע מעשה בלתי חוקי על-פי דיני מדינת ישראל, או מעשה הנחזה על פניו כבלתי חוקי כאמור, או כדי לאפשר, להקל, לסייע או לעודד ביצועו של מעשה כזה; <br />
                        &nbsp; -אם הופרו תנאי התקנון; <br />
                        &nbsp; -אם נעשתה על ידי גולש כל פעולה שתמנע מאחרים להמשיך ולהשתמש באתר בכל דרך שהיא. <br />
                        &nbsp; -אין לראות במידע בדיוור משום הבטחה לתוצאה כלשהי ו/או אחריות למוצר ו/או לשירות המוצע בו. <br />
                        &#x2022; האתר לא יהיה אחראי לכל נזק (ישיר או עקיף), הפסד, עגמת נפש והוצאות שייגרמו למשאיר פרטים ו/או לצדדים שלישיים כלשהם בעקבות שימוש או הסתמכות על כל תוכן, מידע, נתון, מצג, פרסומת, מוצר, שירות וכד’ המופיעים בדיוור. כל הסתמכות כאמור נעשית על-פי שיקול דעתו ואחריותו הבלעדית של משאיר הפרטים. <br />
                        &#x2022; הדיוור בכללותו, לרבות כל המידע המופיע בו, מוצע כמות שהוא, ויהיה מדויק ונכון ככל הניתן, ואולם, יתכן והמידע אינו שלם או לחלופין, יתכן ונפלו טעויות טכניות או אחרות במידע. <br />
                        &#x2022; הגולש מאשר כי לא תהיה לו כל טענה בקשר למודעות פרסום ו/או פרסומות המוצגות באתר, לרבות בקשר למיקומן באתר. מובהר כי בכל הנוגע למודעות פרסום המוצגות בחסות צד שלישי, לאתר אין כל התערבות בבחירת הפרסומות המוצגות, אמיתות תוכנן וסדר הופעתן. <br /> <br />


                        <h3 style={{ textDecorationLine: "underline" }}>9. סיום:</h3>
                        &#x2022; תנאי תקנון זה יישארו בתוקפם בעת השימוש באתר. מבלי לגרוע מכל הוראה אחרת בתקנון זה, האתר שומר על זכותו למנוע מכל גולש את השימוש באתר (לרבות חסימת כתובות IP מסוימות), מכל סיבה או נימוק שהוא (מבלי שיצטרך לספק סיבה או נימוק), בשיקול דעתו הבלעדי, ללא צורך בהודעה מוקדמת או התראה ומבלי שיהיה אחראי לנזק כלשהו עקב החלטתו. כמו כן, האתר רשאי להפסיק את השימוש שלך בו וכל תוכן או מידע שפרסמת בכל עת, ללא אזהרה מראש. <br /> <br />


                        <h3 style={{ textDecorationLine: "underline" }}>10. שינויים באתר, תקלות והפסקות שירות:</h3>
                        &#x2022; האתר שומר את הזכות לשנות מעת לעת או להסיר את תוכן האתר מכל סיבה שהיא לפי שיקול דעתו הבלעדית וללא הודעה מוקדמת. האתר אינו מחויב לעדכן מידע או תוכן כלשהו באתר. <br />
                        &#x2022; האתר לא יהיה אחראי כלפיך או כלפי צד שלישי כלשהו עבור שינוי, השעיה או הפסקת שירות כאמור. <br />
                        &#x2022; האתר אינו מתחייב ששירותי האתר לא יופרעו, יינתנו כסדרם או בלא הפסקות, יתקיימו בבטחה וללא טעויות ויהיו חסינים מפני גישה בלתי מורשית למחשבי האתר או מפני נזקים, קלקולים, תקלות או כשלים – והכל, בחומרה, בתוכנה, בקווי ובמערכות תקשורת אצל האתר או אצל מי מספקיו. <br />
                        &#x2022; האתר שומר על זכותו לשנות, בכל עת וללא הודעה מוקדמת, את התנאים וההתניות בתקנון זה החלים על השימוש באתר ושינויים אלו ייכנסו לתוקפם מיד עם הכנסתם לתקנון. <br /> <br />



                        <h3 style={{ textDecorationLine: "underline" }}>11. המוצרים המוצעים לרכישה באתר:</h3>
                        &#x2022; שמות המוצרים המוצעים לרכישה באתר יופיעו בדפי האתר. <br />
                        &#x2022; האתר אינו מחויב, בכל דרך שהיא, לקיים מגוון כלשהו של מוצרים. <br />
                        &#x2022; האתר רשאי בכל עת לשנות את מגוון המוצרים המוצעים לרכישה באתר, להחליפם להמעיט מהם, להוסיף עליהם, ללא כל הודעה או התראה מוקדמת. <br />
                        &#x2022; אופן הצגת המוצרים באתר הינו על-פי שיקול דעתו הבלעדי של האתר. <br /> <br />



                        <h3 style={{ textDecorationLine: "underline" }}>12. המחירים באתר:</h3>
                        &#x2022; מחירים, זמינות ותנאי רכישה אחרים מופיעים בדפי האתר ועשויים להשתנות מעת לעת. האתר שומר לעצמו את הזכות לשנות את מגוון המוצרים ו/או השירותים או להפסיקם או לשנות את מחיר המוצרים ו/או השירותים המוצעים באתר ומחוצה לו, ללא הודעה מוקדמת. <br />
                        &#x2022; יובהר, כי בעת הזמנת מוצרים שקילים אומדן ערך ההזמנה שנמסר ללקוח מתבצע על פי הערכת המשקל של המוצרים השקילים המוזמנים, ואולם בעת אספקת המוצרים תיתכן סטיית תקן של עד 10%. <br />
                        &#x2022; בעת הזמנה באתר, אתה אחראי לספק מידע נכון, מדויק, עדכני ומלא. האתר רשאי לאמת את הפרטים שהוזנו לאחר ביצוע הזמנה, והוא רשאי לבטל או להגביל את ההזמנה בכל עת. במידה ובוצע תשלום עבור הזמנה שבוטלה, האתר יחזיר את סכום התשלום לאותו אמצעי התשלום. <br />
                        &#x2022; אם, מכל סיבה שהיא, חברת כרטיס האשראי תסרב להעביר את סכום התשלום בגין רכישה באתר, האתר יהיה רשאי להשהות או להפסיק לאלתר את מתן השירות והוא רשאי לדרוש את התשלום בתוספת עלות שנגרמה לאתר עקב הסירוב (לרבות קנסות מצד צד שלישי) באמצעים אחרים, לשיקול דעתו של האתר. במידה והאתר יאלץ לנקוט בהליכים משפטיים לגביית יתרות תשלום, אתה מתחייב לשפות את האתר בכל ההוצאות שיגרמו לו, לרבות שכר טרחת עורך דין והוצאות משפט נוספות. <br />
                        &#x2022; רכישה באתר כפופה לתנאים ולהגבלות נוספות של חברות האשראי ו/או כל אמצעי תשלום אחר המופיע באתר. <br /> <br />



                        <h3 style={{ textDecorationLine: "underline" }}>13. מדיניות אספקה:</h3>
                        &#x2022; האתר ידאג לאספקת הזמנה של מוצרים בכל חלקי הארץ אליהם מגיע חברת המשלוחים בהובלה סטנדרטית בהתאם להזמנה שבוצעה באתר ולכתובת הלקוח כפי שהוזנה על ידו בעת ביצוע הרכישה באתר. <br />
                        &#x2022; הזמנות בתחומי אילת וקיבוץ איילות יטופלו ויסופקו בלוחות הזמנים הבאים: <br />
                        &nbsp; -עד 72 שעות באמצעות שליח עד הבית; <br />
                        &nbsp; -״יום עסקים״ – ימי ראשון עד חמישי, להוציא ימי שבתון של כלל המשק, ערבי חג וימים בהם הבנקים בישראל לא עובדים. <br />

                        &nbsp; -עלות ההזמנה אינה כוללת את עלות המשלוח. עלות המשלוח עשויה להתעדכן מעת לעת בהתאם לאמור בעת ביצוע ההזמנה באתר. <br />
                        &nbsp; -האתר רשאי לקבוע סכום מינימום להזמנה באתר. <br />
                        &nbsp; -במקרה שבו מוצר שהוזמן באתר אינו זמין במלאי, האתר יעדכן את המזמין. במידה והמזמין לא יהיה זמין, האתר ישלח את ההזמנה ללא המוצר החסר. <br />
                        &nbsp; -במידה והלקוח לא יהיה נוכח בביתו בעת הגעת השליח, בשעה שתואמה אתו מראש, ההזמנה תוחזר ותשלח פעם נוספת לאחר תשלום דמי משלוח נוספים על ידי הלקוח. <br />
                        &#x2022; אספקת הזמנות תתאפשר בכפוף לתנאים הבאים: <br />
                        &nbsp; -ישנה נגישות סבירה למקום ההזמנה; <br />
                        &nbsp; -האתר (לרבות חברת השליחויות שלו) מבצע חלוקה באותו אזור; <br />
                        &nbsp; -אם מדובר בארגון, מוסד, קיבוץ או ישוב שלא ניתן להגיע אליהם ללא אישור מיוחד ו/או שאין בהם רישום מוסדר של רחובות ו/או מספרי בתים, ההזמנה תימסר לנקודת החלוקה המרכזית באותו המקום. <br />
                        &#x2022; האתר לא יהיה אחראי לכל איחור ו/או עיכוב באספקה ו/או אי-אספקה, שיגרם כתוצאה של: <br />
                        &nbsp; -כוח עליון ו/או מאירועים שאינם בשליטת האתר; <br />
                        &nbsp; -מסיבות הקשורות לחברת השליחויות של האתר; <br />
                        &nbsp; -סגירת המשק כך שפעילות האתר ו/או ספקיו ו/או נותני שירותיו יפגעו, מכל סיבה שהיא. <br /> <br />



                        <h3 style={{ textDecorationLine: "underline" }}>14. מדיניות החזר, שינוי וביטול הזמנות:</h3>
                        &#x2022; בקשה להחזר, שינוי או ביטול עסקה יועברו לאתר באחד מאמצעי ההתקשרות המופיעים בתחתית התקנון. <br />
                        &#x2022; החזר, שינוי או ביטול הזמנה יתאפשרו בהחזר כספי או בהחלפה בטווח זמן של עד 14 יום לאחר קבלת הלקוח את ההזמנה. <br />
                        &#x2022; ההחזר הכספי יעשה באמצעי התשלום שבו ביצע הלקוח את ההזמנה. <br />
                        &#x2022; בהתאם לחוק הגנת הצרכן, תשמ”א-1981, לא תתאפשר החזרה של מוצרי מזון, תרופות ו/או תוספי תזונה. <br />
                        &#x2022; במידה וההחזר, השינוי או הביטול יתבקשו לאחר 14 יום מקבלת ההזמנה, בקשה להחזר, שינוי או ביטול עסקה יתאפשרו בכפוף לשקול דעתו הבלעדי של האתר. <br />
                        &#x2022; החזרה, שינוי או ביטול יאושרו לאחר שהלקוח יקבל הודעה בכתב מהאתר המאשרת זאת. <br />
                        &#x2022; במקרה שהחזר, שינוי או ביטול נעשו מפני שהלקוח התחרט (ולא עקב פגם או אי התאמה) ואושרו על ידי האתר: <br />
                        &nbsp; -על הלקוח להשיב את המוצר לאתר כאשר הוא במצבו כפי שקבל אותו; <br />
                        &nbsp; -לא נעשה במוצר שימוש מכל סוג שהוא; <br />
                        &nbsp; -המוצר יוחזר בצירוף חשבונית עסקה או הוכחת עסקה; <br />
                        &nbsp; -העלויות הכרוכות בהחזרת ההזמנה תחולנה על הלקוח; <br />
                        &nbsp; -יגבו דמי ביטול בגובה 5% מסכום המוצר או בסך 100 ש״ח (לפי הנמוך מבניהם). <br />
                        &#x2022; במקרה שהחזר, שינוי או ביטול נעשו עקב פגם במוצר, האחריות לאיסוף או החלפת המוצר הפגום מהמקום בו נמסר, תחול על האתר. <br />
                        &#x2022; מוצר שיוגדר כפגום על ידי הלקוח ייבדק על ידי האתר. במידה ולאחר בדיקת האתר המוצר ימצא תקין ו/או שהפגם נעשה לאחר קבלת הלקוח את המוצר כתוצאה של שימוש שאינו סביר, הלקוח לא יקבל החזר כספי ויהיה באפשרותו לבקש את שליחת המוצר חזרה אליו בכפוף לתשלום דמי משלוח פעם נוספת. <br />
                        &#x2022; האמור בתקנון ובסעיף 14 לעיל כפוף להוראות חוק הגנת הצרכן, התשמ”א-1981. <br /> <br />



                        <h3 style={{ textDecorationLine: "underline" }}>15. סמכות שיפוט:</h3>
                        &#x2022; על תקנון זה והשימוש באתר יחולו אך ורק דיני מדינת ישראל, אולם לא תהיה תחולה לכללי ברירת הדין הבינלאומי הקבועים בהם. <br />
                        &#x2022; לבתי המשפט במחוז השיפוט של בית המשפט באילת תהיה סמכות שיפוט ייחודית בכל עניין הנובע ו/או הקשור לתקנון ו/או למחלוקות משפטיות שיתגלו בינך לבין האתר. <br /> <br />



                        <h3 style={{ textDecorationLine: "underline" }}>16. דיוק/ תיקונים טכניים ואחרים:</h3>
                        &#x2022; האתר בכללותו, לרבות כל המידע המופיע בו, מוצע לציבור כמות שהוא, ויהיה מדויק ונכון ככל הניתן, ואולם, יתכן והמידע אינו שלם או לחלופין, יתכן ונפלו טעויות טכניות או אחרות במידע. האתר שומר על זכותו לתקן טעויות ו/או שגיאות ו/או אי דיוקים כאמור ולעדכן את המידע באתר בכל עת וללא הודעה מוקדמת. <br /> <br />



                        <h3 style={{ textDecorationLine: "underline" }}>17. אחריות האתר:</h3>
                        &#x2022; התכנים באתר ניתנים לשימוש כמות שהם (AS IS). לא ניתן להתאימם לצרכיו של כל אדם ואדם. לא תהיה לגולש כל טענה, תביעה או דרישה כלפי האתר בגין תכונות של התכנים, יכולותיהם, מגבלותיהם ו/או התאמתם לצרכיו והשימוש באתר יהיה על אחריותו הבלעדית של הגולש באתר. <br />
                        &#x2022; השירותים, המידע והתכנים המוצגים באתר הם אינפורמטיביים וכלליים, ומובאים ככלי ללימוד עצמי ולהעשרה בלבד, וכל פעולה שתיעשה בעקבותיהם תיעשה באחריות הלקוח בלבד. <br />
                        &#x2022; תמונות המוצרים באתר מוצגות לצורכי המחשה בלבד. ייתכנו הבדלים מסוימים במראה, בגוון, בגודל, וכיו”ב בין המוצר, כפי שהוא מוצג באתר, לבין המוצר שיסופק ללקוח. טעות סופר בתיאור מוצר ו/או מחירו לא יחייב את האתר. <br />
                        &#x2022; המוצרים המופיעים באתר עשויים להיות מיוצרים ו/או מיובאים ו/או משווקים ו/או מסופקים על ידי צדדים שלישיים. לקוחות האתר מאשרים ומצהירים כי הם מודעים לכך ושהם יישאו באחריות לפנות ולדרוש מהיצרן ו/או היבואן ו/או המשווק ו/או הספק פיצוי בגין כל נזק, ישיר או עקיף, לרבות נזק גוף, אשר ייגרם להם שהוא מעבר לעלות המוצר. <br />
                        &#x2022; מוצרי המזון המוצגים באתר עשויים להכיל אלרגנים (כגון אגוזים, סויה, גלוטן, לקטוז ועוד), ואין להסתמך על הפירוט או התיאור המופיע באתר. יש לקרוא את המופיע על גבי המוצר לפני השימוש בו ו/או צריכתו. במקרים של תופעות אלרגיות בלתי רצויות, יש לחדול מאכילת המוצר ולפנות מיד לרופא. <br />
                        &#x2022; השירותים, המידע והתכנים המוצגים באתר אינם מובאים כתחליף לטיפול ו/או ייעוץ ו/או המלצה רפואיים ו/או מקצועיים, ואין לראות בהם טיפול ו/או ייעוץ ו/או המלצה של אנשי מקצוע מוסמכים, כגון רופאים, דיאטנים וכדומה. <br />
                        &#x2022; המוצרים המוצגים באתר אינם תרופות או תחליף לתרופות, ואינם מוגדרים לטיפול ו/או מניעה של מחלות, ובכל מקרה יש להיוועץ עם איש מקצוע כגון רופא וכד’. <br />
                        &#x2022; לאוכלוסיות רגישות דוגמת נשים הרות, תינוקות, ילדים ואנשים החולים במחלות כרוניות והנוטלים תרופות מרשם מומלץ להיוועץ ברופא טרם שימוש במוצר. <br />
                        &#x2022; השימוש במידע המוצג באתר ובמוצרים הנמכרים בו נעשית על אחריותו הבלעדית של הלקוח ולא תהיה לו כל טענה כלפי האתר, מנהליו, עובדיו או מי מטעמו והם לא יהיו אחראים, באופן ישיר או עקיף, לכל נזק לרבות, לגוף, לרכוש או לכל נזק אחר או לכל הפסד אחר, אשר יגרם כתוצאה משימוש בתכנים ובמוצרים המוצגים באתר, אפילו אם האתר הזהיר, המליץ או ייעץ לעניין מסוים או על מוצר מסוים ובשום מקרה האתר לא יהווה תחליף או יישא באחריות הלקוח. על הלקוח חלה האחריות וכל סיכון וחובות עבור נזק וחבלה לגופו ו/או לרכושו ו/או לרכושם ו/או לגופם של צדדים שלישיים, הנובעים מהשימוש ו/או אי השימוש בתכנים ובמוצרים. <br />
                        &#x2022; אין להסתמך על פירוט או תיאור מוצר המופיע באתר ויש לקרוא בעיון את תווית המוצר והעלון לצרכן (ככל וישנם) טרם השימוש בו והאחריות הינה על צורך המוצר. במקרים של תופעות אלרגיות בלתי רצויות, יש לחדול משימוש במוצר ולפנות מיד לרופא. <br />
                        &#x2022; האחריות למוצר פגום או מקולקל מוגבלת להחלפת המוצר או שווי המוצר. החבות הכוללת של האתר ביחס לכל מוצר לא תעלה בשום מקרה על מחיר הרכישה של אותו המוצר. <br />
                        &#x2022; מבלי לגרוע מהאמור לעיל, קריאה של תקנון האתר אינה מהווה הוראה ו/או התוויה ו/או עצה רפואית. אין לייחס לאמור בתקנון זה תחליף למעקב קפדני אחר הוראות השימוש המופיעות על גבי אריזות המוצרים ו/או בעלון לצרכן. <br />
                        &#x2022; האתר לא יהיה אחראי לכל נזק (ישיר או עקיף), הפסד, עגמת נפש והוצאות שייגרמו לגולשים ו/או לצדדים שלישיים כלשהם בעקבות שימוש או הסתמכות על כל תוכן, מידע, נתון, מצג, תמונה, וידאו, אודיו, פרסומת, מוצר, שירות וכו’ המופעים באתר. כל הסתמכות כאמור נעשית על-פי שיקול דעתו ואחריותו הבלעדית של הגולש באתר. <br />
                        &#x2022; מידע ומצגים אודות מוצרים המוצגים באתר, שמקורם בשותפיה העסקיים של האתר שמוצריהם מופיעים באתר (ככל ויופיעו) וכל תוכן ביחס למוצרים נמצאים באחריותם הבלעדית של השותפים העסקיים כאמור, ועל כן מובן שלאתר אין כל אחריות בגין מידע מעין זה, ואין האתר ערב למידת הדיוק של מידע זה. <br />
                        &#x2022; האתר בשום מקרה לא יהיה אחראי לנזק שנגרם לגולש האתר באמצעות יצירת קשר עם שותפיו העסקיים של האתר. <br />
                        &#x2022; האתר ממליץ לגולשים באתר לנהוג כצרכנים נבונים וזהירים, ולקרוא בעיון את המידע המוצג באתר ובכלל זה את המידע ביחס למוצר עצמו, תיאורו והתאמתו, כמתואר באתר. <br />
                        &#x2022; האתר בכללותו, לרבות כל המידע המופיע בו, מוצע לציבור כמו שהוא, ויהיה מדויק ונכון ככל הניתן, ואולם, יתכן והמידע אינו שלם או לחלופין, יתכן ונפלו טעויות טכניות או אחרות במידע. <br />
                        &#x2022; השימוש באתר ייעשה על אחריותו הבלעדית והמלאה של כל גולש. האתר אינו מתחייב כי תכנים ושירותים המתפרסמים באתר, יהיו מלאים, נכונים, חוקיים או מדויקים או יהלמו את ציפיותיו ודרישותיו של כל גולש. האתר לא יישא באחריות כלשהי לכל תוצאה שתנבע מהם, או משימוש בהם, או מהסתמכות עליהם, לרבות: (1) שגיאות, טעויות ואי-דיוקים; (2) נזק לגוף או לרכוש, מכל סוג, הנגרם עקב השימוש באתר ו/או רכישה באתר; (3) הפרעה בגישה לאתר או מהאתר; (4) כל באג, וירוס, סוסי טרויאני וכיו״ב שעלולים להיות מועברים לאתר על ידי צד שלישי כלשהו. <br />
                        &#x2022; ryc.co.il אינה אחראית לכל נזק שייגרם לך כתוצאה משימוש באתר ו/או מהסתמכות על המידע המופיע בו ו/או מהמוצרים הנמכרים בו ו/או מהשירותים המוצעים בו ו/או מהפרסומות המוצגות בו ו/או מהקישורים המופיעים בו.  <br /> <br />




                        <h3 style={{ textDecorationLine: "underline" }}>18. מדיניות פרטיות:</h3>
                        &#x2022; האתר מכבד את פרטיות הלקוחות. <br />
                        &#x2022; בנוסף למידע אותו הנך מוסר בעת הרשמתך לאתר, האתר אוסף מידע מסוים אודות המחשב שלך, אשר באמצעותו הנך מבקר ומבצע פעולות באתר. המידע נאסף באופן אוטומטי (לרבות באמצעות שימוש ב “Cookies”) והוא עשוי לכלול כתובות IP, סוג דפדפן, זמני גלישה וכניסה לאתר, אופן הגלישה והכלי בו הנך משתמש למטרת הגלישה, פרטים לגבי ספק האינטרנט שלך וכתובות אתרי האינטרנט מהם הגעת. המידע הנמסר בעת ההרשמה והמידע הנאסף על ידי האתר כאמור בפסקה זו יכונו יחד: “המידע הנאסף באתר”). <br /> המידע הנאסף באתר עשוי לשמש את האתר לצרכים הבאים: <br />
                        &nbsp; -לספק לך שירותים ולשפר את האתר ו/או את השירותים; <br />
                        &nbsp; -תפעולו התקין של האתר;<br />
                        &nbsp; -לנתח את ולנהל את האתר באופן תקין; <br />
                        &nbsp; -שיפור שירות הלקוחות של האתר; <br />
                        &nbsp; -ליצירת קשר או לספק לך נתונים בקשר לאתר או לשירות; <br />
                        &nbsp; -בכדי לספק לך את המידע שביקשת או מידע נוסף אשר באתר מאמינים שהוא עשוי לעניין אותך, מעת לעת; <br />
                        &nbsp; -בכדי להתאים פרסומות ומידע מסחרי על פי העדפותיך האישיות; <br />
                        &nbsp; -בכדי ליצור עמך קשר על ידי נציגי האתר בנוגע למוצרים אותם האתר מספק; <br />
                        &nbsp; -בכדי לבצע סקרי לקוחות ו/או מחקרי שיווק אשר האתר עורך מעת לעת. <br />
                        &#x2022; האתר ו/או מי מטעמו לא יגלו ו/או ימכרו כל מידע הנאסף באתר לכל צד שלישי כלשהו, וזאת למעט למי מטעמם להם הם מספקים את המידע הנאסף באתר אך ורק לצורך מתן שירות והשלמת הליך רכישה שבוצע באתר, ואשר אף הם מחויבים להגנה מלאה על סודיות המידע הנאסף באתר. <br />
                        &#x2022; יחד עם זאת, האתר ו/או מי מטעמו יהיו רשאים להעביר מידע הנאסף באתר לצדדים שלישיים (למעט מידע רגיש כגון פרטי אשראי, מספרי תעודות זהות וכיו״ב) <br />בכל אחד מהמקרים הבאים: <br />
                        &nbsp; -ניתנה לכך הסכמתך מראש ובכתב; <br />
                        &nbsp; -האתר ו/או מי מטעמו נדרשים על פי חוק להעביר את המידע האמור, למשל, מכוח צו בית משפט; <br />
                        &nbsp; -על מנת להגן על זכויותיהם המשפטיות והקנייניות של האתר ו/או מי מטעמו בכל הנוגע לאתר זה; <br />
                        &nbsp; -במקרה של מחלוקת משפטית בינך לבין האתר אשר תחייב חשיפת הפרטים; <br />
                        &nbsp; -אם תפר את התקנון של האתר או אם תבצע באמצעות האתר, או בקשר כלשהו עם האתר, פעולות מנוגדות או הנחזות כמנוגדות לדין, או כל ניסיון לבצע פעולות כאלה; <br />
                        &nbsp; -בכל מקרה שהאתר יסבור, כי מסירת המידע הנאסף נחוצה על מנת למנוע נזק חמור לגוף המשתמש או לרכושו או לגופו או לרכושו של צד שלישי; <br />
                        &nbsp; -שיתוף רשתות חברתיות כגון פייסבוק, אינסטגרם ואחרות ומפרסמים נוספים ברשת כגון גוגל, טאבולה ואחרים. <br />
                        כמו כן, יתכן שהאתר יעביר לצדדים שלישיים מידע סטטיסטי כללי בלבד, שאינו אישי או פרטי, לגבי השימוש באתר, כגון המספר הכולל של המבקרים באתר זה ובכל עמוד של האתר וכן שמות הדומיין של נותני שירות האינטרנט של המבקרים באתר;

                        <br /> &#x2022; אם האתר ימוזג לתוך פעילות גוף אחר או אם האתר יעבור לבעלות תאגיד אחר ניתן יהיה להעביר לתאגיד החדש את המידע הקיים באתר, אבל רק במקרה שהתאגיד יתחייב לשמור על מדיניות פרטיות זו. <br />
                        &#x2022; חשוב לזכור שלא ניתן לערוב במאת האחוזים מפני פעילות עוינת ונחושה מצד גורמים זרים ולכן אין בפעולות אלה בטחון מוחלט והאתר לא מתחייב שהשירותים באתר יהיו חסינים באופן מוחלט מפני גישה בלתי מורשית למידע הנאסף בו.  <br /> <br />



                        <h3 style={{ textDecorationLine: "underline" }}>19. שיפוי:</h3>
                        &#x2022; על הגולש לשפות את האתר, עובדיו, מנהליו, שותפיו העסקיים או מי מטעמם בגין כל נזק, הפסד, אבדן רווח, תשלום או הוצאה שייגרמו להם – ובכלל זה שכ”ט עו”ד והוצאות משפט עקב הפרת התקנון. <br /> <br />



                        <h3 style={{ textDecorationLine: "underline" }}>20. שונות:</h3>
                        &#x2022; תנאי תקנון זה מהווים את כל ההסכמות וההבנות בנוגע לשימוש באתר. אי מימוש או אכיפה של זכות או הוראה בתקנון זה, לא תחשב כוויתור מצד האתר על מימוש הזכות או אכיפת ההוראה. האתר יהיה רשאי להמחות לאחרים את כל או חלק מזכויותיו ו/או חובותיו בתקנון זה. <br /> <br />



                        <h3 style={{ textDecorationLine: "underline" }}>21.  צרו קשר:</h3>
                        &#x2022; האתר מקפיד על קיום הוראות החוק ומכבד את זכותם של משתמשי האתר ואחרים לפרטיות ולשם טוב. אם אתה סבור כי פורסם באתר תוכן הפוגע בך מטעם כלשהו, נא פנה אלינו לפי הפרטים שלהלן ואנו נשתדל לטפל בפנייתך בכל ההקדם. פניות כאמור ניתן להעביר באמצעים הבאים: <br /> <br />
                        כתובת: <br />

                        {web.contact.address}; <br /> <br />

                        טלפון: <br />

                        {web.contact.phone}; <br /> <br />

                        דוא”ל: <br />

                        {web.contact.email} <br /> <br />

                        עדכון אחרון: אפריל 2024
                    </div> </div> : null

            }

        </div>
    );
}

export default Terms;