import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams, useSearchParams } from 'react-router-dom';
import GetWebData from '../function/GetWebData';
import { Helmet } from 'react-helmet';
import '../css/home.css';
import ProductSelectCard from './comp/ProductSelectCard';

function Home(props) {
  const web = useSelector((state) => state.setWeb.web.web);
  const products = useSelector((state) => state.setProducts.products);
  const onSaleProducts = products.filter((product) => product.onSalePrice > 0);
  const reversedProducts = [...products].reverse().slice(0, 6);
  
  

  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      // Increment the index, and loop back to the beginning if it exceeds the array length
      setCurrentIndex((prevIndex) => (prevIndex + 1) % web.upBunner.length);
    }, 4000);

    // Clear the interval when the component is unmounted or when the array of images changes
    return () => clearInterval(interval);
  }, [currentIndex, web.upBunner]);

  const shuffleArray = (array) => {
    return array
      .map((value) => ({ value, sort: Math.random() }))
      .sort((a, b) => a.sort - b.sort)
      .map(({ value }) => value);
  };

  const shuffledProducts = shuffleArray(onSaleProducts);


  return (

    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{web.name}</title>
        <link rel="icon" href={`https://ryc-sale-ryc-imageserver-sale.emzsne.easypanel.host/images/${web.logo}`} />
      </Helmet>


      {web ? (
        <div className='home-container'>

          

          {/* on sale products                               */}
          {onSaleProducts.length > 0 ?
            <>
              <div>
                
              </div>
              <div className='home-product-card'>
                {shuffledProducts.map((product, index) => (
                  product.showOnHome ? (
                   <ProductSelectCard homePage={true} key={index} category={product.category[0]} product={product} />
                  ) : null
                  // <ProductSelectCard homePage={true} key={index} category={product.category[0]} product={product} />
                ))}

              </div>
            </>
            : null
          }
          {/* ------------------------------------------ */}





         


         

          {/* ------------------------------------------ */}



         






        </div>
      ) : (
        <>
          <GetWebData />
        </>

      )}
    </div>
  );
}

export default Home;