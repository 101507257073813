import React from 'react';
import { useSelector } from 'react-redux';
import { Helmet } from 'react-helmet';
import '../css/about.css';


function About(props) {
   
    const web = useSelector((state) => state.setWeb.web.web);
    return (
        <div className='abote-container'>
            <Helmet>
                <meta charSet="utf-8" />
                <title>{web.name}-אודות</title>
                <link rel="icon" href={`https://ryc-sale-ryc-imageserver-sale.emzsne.easypanel.host/images/${web.logo}`} />
            </Helmet>
            <div className='about-image-container' >
                {web.aboutBunner === "" ? null :
                    <img className='about-image' src={`https://ryc-sale-ryc-imageserver-sale.emzsne.easypanel.host/images/${web.aboutBunner}`} alt="" />
                }
                <h1 className='abote-h1-about'>אודותנו</h1>
                <h1 className='about-h1-stroy-of-name'>הסיפור של {web.name}</h1>
            </div>

            <div className='about-text-container'>
                <p className='about-p'>
                    {web.about}
                </p>
            </div>
        </div>
    );
}

export default About;