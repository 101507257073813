import React from 'react';
import "./footer.css";
import { FaInstagram } from "react-icons/fa";
import { FaFacebook } from "react-icons/fa";
import { FaWaze } from "react-icons/fa";
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
function Footer(props) {

    const web = useSelector((state) => state.setWeb.web.web);


    return (
        <div>

            {web ?
                <div style={{ backgroundColor: "#333" }} className='footer' >
                    <div className="sb-footer section-padding">
                      <div style={{marginBottom:"2px"}}>
                        <Link style={{color:"white"}} to={`/${web.name}/terms`}>
                        תקנון
                        </Link>
                      </div>
                        <hr />
                        <div className="sb-footer-below">
                            <div className="sb-footer-copyright">
                                <p>
                                    כל הזכויות שמורות  © ryc {new Date().getFullYear()}
                                </p>
                            </div>
                            <div className="sb-footer-below-links">
                                
                                <a href="https://ryc.co.il/"><div><p>ryc.co.il</p></div></a> &nbsp; &nbsp;
                            </div>
                        </div>

                    </div>
                </div>

                : null
            }

        </div>
    );
}

export default Footer;