import React from 'react';
import { useSelector } from 'react-redux';
import { Helmet } from 'react-helmet';
import '../css/contact.css';

function Contact(props) {


    const web = useSelector((state) => state.setWeb.web.web);



    return (
        <div className='contact-container'>
            <Helmet>
                <meta charSet="utf-8" />
                <title>{web.name}-צור קשר</title>
                <link rel="icon" href={`https://ryc-sale-ryc-imageserver-sale.emzsne.easypanel.host/images/${web.logo}`} />
            </Helmet>

                <h1 className='contact-h1'>כתובת וטלפון</h1>
            <div className="contact-data">
                <h3 style={{marginBottom:"10px"}}>{web.contact.address}</h3>
                <h3>{web.contact.phone}</h3>
            </div>

            <h1 className='contact-h1' style={{marginTop:"80px"}}>דואר אלקטרוני</h1>
            <div className="contact-data">
                <h3 style={{marginTop:"20px"}}>{web.contact.email}</h3>
            </div>
        </div>
    );
}

export default Contact;