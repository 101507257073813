import React from 'react';
import { Helmet } from 'react-helmet';
import { useSelector, } from 'react-redux';
import { Link, Outlet } from 'react-router-dom';
import "../css/products.css"

function Products(props) {
    const web = useSelector((state) => state.setWeb.web.web);

    return (
        <div className='container'>

            <Helmet>
                <meta charSet="utf-8" />
                <title>{`${web.name}-מוצרים`}</title>
                <link rel="icon" href={`https://ryc-sale-ryc-imageserver-sale.emzsne.easypanel.host/images/${web.logo}`} />
            </Helmet>

            {web ?
                <>
                    <h1>  קטגוריות  </h1>
                    {web.category.length !== 0 ?
                        <div className='categoryContainer' >

                            {web.category.map((category, index) => {
                                return (

                                    <div  className='categoryItem' key={index} >
                                        <img className='img' src={`https://ryc-sale-ryc-imageserver-sale.emzsne.easypanel.host/images/${category.image}`} alt="" />
                                        <Link to={`/${web.name}/products/${category.name}`}>
                                            <h4 style={{backgroundColor:web.color}} className='h' >{category.name}</h4>
                                        </Link>
                                    </div>
                                )
                            })}
                        </div> :

                        <h3 style={{ margin: "50px", marginBottom: "400px" }}>עדיין לא נוסף קטגריות</h3>
                    }

                </>
                : null
            }
            <Outlet />
        </div>
    );
}




export default Products;
